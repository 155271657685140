<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb">
    </div> -->
    <!--主体内容-->
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="submitData">
            <el-form label-position="right" label-width="150px" :model="formInline"   :ref="'form'">
              <div style="text-align: right;width: 100%;">
                <el-button type="" @click="$router.go(-1)" style="width: 88px;margin-bottom: 20px">返回</el-button>
              </div>
              <div style="border: 1px solid #C0CCDA;border-radius: 4px;padding:15px;">
                <h2 class="title">
                <div class="title_icon">
                </div>
                  <span>条件派发详情</span>
              </h2>
                  <div style="width: 900px;">
                    <el-form-item label="活动名称:">
                      <span>{{formInline.couponActivityName}}</span>
                      <!--<el-input :maxlength='20' v-model.trim="formInline.couponActivityName" placeholder="输入停车场名称"></el-input>-->
                    </el-form-item>
                    <el-form-item label="活动时间:">
                      <span>{{formInline.start}}</span>
                      <!--<el-date-picker v-model.trim="formInline.start" type="daterange" placeholder="选择日期" :picker-options="pickerOptions1" value-format="yyyy-MM-dd">
                      </el-date-picker>-->
                    </el-form-item>
                    <el-form-item label="关联优惠券" prop="couponRuleIds">
                      <template>
                        <p v-for="item in formInline.couponRuleActivities" :key="item.couponRuleId">
                          {{ item.couponName }}
                          {{ item.publishAmount }}张</p>
                      </template>
                    </el-form-item>
                    <!-- <el-form-item label="领取规则:" prop="oneDayTimes">
                      <template>
                        <p>活动期间每人每日可领取{{ formInline.onePersonOneDayTimes }}次</p>
                        <p>活动期间每人总共可领取{{ formInline.onePersonTotalTimes }}次</p>
                      </template>
                    </el-form-item> -->
                    <el-form-item label="发行量:" prop="publishAmount">
                      <span>{{ formInline.publishAmount }}</span>
                    </el-form-item>
                    <el-form-item label="领取量:" prop="receiveAmount">
                      <span>{{formInline.receiveAmount}}</span>
                    </el-form-item>
                    <el-form-item label="使用量:" prop="usedAmount">
                      <span>{{formInline.usedAmount}}</span>
                    </el-form-item>
                    <el-form-item label="活动状态:" prop="status">
                      <span>{{formInline.status | statusFilter}}</span>
                    </el-form-item>
                    <el-form-item label="触发条件:" prop="fireConditions">
                      <span>{{formInline.fireConditions | fireConditionsFilter(fireConditionsList)}}</span>
                    </el-form-item>
                    <el-form-item label="推动通知:" prop="couponPushMode">
                      <span>{{formInline.couponPushMode | couponPushModeFilter}}</span>
                      <!-- <span>{{formInline.couponPushMode }}</span> -->
                    </el-form-item>
                    <!-- <el-form-item label="审核状态:" prop="auditStatus">
                      <span>{{formInline.auditStatus}}</span>
                    </el-form-item> -->
                    <el-form-item label="创建人:" prop="creatorName">
                      <span>{{formInline.creatorName}}</span>
                    </el-form-item>
                    <el-form-item label="创建时间:" prop="createdTime">
                      <span>{{formInline.createdTime}}</span>
                    </el-form-item>
                    <!-- <div v-if="isauditor">
                      <el-form-item label="审核人:" prop="auditorName">
                        <span>{{auditorName}}</span>
                      </el-form-item>
                      <el-form-item label="审核时间:" prop="auditTime">
                        <span>{{formInline.auditTime}}</span>
                      </el-form-item>
                    </div> -->
                    <!-- <div v-if="unloadUser">
                      <el-form-item label="下架人:" prop="unloadUserName">
                        <span>{{unloadUserName}}</span>
                      </el-form-item>
                      <el-form-item label="下架时间:" prop="unloadTime">
                        <span>{{formInline.unloadTime}}</span>
                      </el-form-item>
                    </div> -->
                  </div>
              </div>
            </el-form>


      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'conditionalDistributionsee',
    filters: {
      statusFilter (val) {
        const arr = ['未审核', '未开始', '进行中', '已结束', '已下架']
        return arr[val]
      },
      couponPushModeFilter (val) {
        let value = val.toString();
        let list = value.split(','), strList = []
        list.forEach(ele => {
          if (ele === '0') {
            strList.push('APP推送')
          } else if (ele === '1') {
            strList.push('短信推送')
          }
        })
        return strList.join(',')
      },
      fireConditionsFilter (val, arr) {
        let value = val;
        let list = value.split(','), strList = []
        list.forEach(ele => {
          let newArr = arr.filter(item => {
            return ele == item.code
          })
          if (newArr.length) {
            strList.push(newArr[0].desc)
          }
        })
        return strList.join(',')
      }
    },
    data () {
      return {
        unloadUser: false,
        isauditor: false,
        // name:'',
        tenantLists: [],
        value5: [],
        value11: [],
        num1: 0,
        radio: '1',
        isEdit: false,
        oldParkname: '',
        hasSelect: '',
        rsTypeList: [],
        parkTypeArr: [{
             name: '地上',
             value: '1'
           }, {
             name: '地下',
             value: '0'
           }, {
             name: '地上+地下',
             value: '2'
           }],
        upData: {
          'Session-Id': sessionStorage.token
        },
        activeName: '1',

        value1: '',
        url: '',
        file: '',
        picFlag: 'false',
        payWayAll: [],
        parkTypeAll: [],
        workStateAll: [],
        tenantList: [],
        headers: {
          'Session-Id': sessionStorage.token
          },
        map: '',
        pic: true,
        temLng: '',
        temLat: '',
        temAddress: '',
        areaList: '',
        city: '',
        creatorName: '',
        auditorName: '',
        unloadUserName: '',
        windows: [],
        formInline: {
          couponActivityName: "",
          start: "",
          startTime: "",
          endTime: "",
          parkType: "",
          publishAmount: "",
          onePersonOneDayTimes: "",
          oneDayTimes: "",
          couponPushMode: [],
          pushRate: [],
          expiredDays: "",
          activityPushMode: [],
          pushContent: "",
          oneDay: '',
          arramount: [],
          fireConditions: ''
        },
        couponActivityId: this.$route.query.couponActivityId,
        fireConditionsList: []
      }
    },
    methods: {
      // 获取触发条件list
      handleGetDroplist() {
      let opt = {
        method: "get",
        url: "/acb/2.0/couponActivity/droplist",
        success: (res) => {
          this.fireConditionsList = res.value
        },
      };
      this.$request(opt);
    },
      // 获取优惠券
      couponRuleIds () {
        let opt = {
          url: '/gplus/couponActivity/getCouponRulesForChoose',
          data: {},
          success: (res) => {
            this.tenantLists = res.data.CouponRuleList;
          }
        };
        // getData(opt);
      },
      // 获取详情
      getDetailInfo () {
        const opt = {
          method: 'get',
          url: '/acb/2.0/couponActivity/getById',
          data: { couponActivityId: this.couponActivityId },
          success: res => {
            const value = res.value
            this.formInline.couponActivityName = value.couponActivityName
            this.formInline.start = value.startTime + ' 至 ' + value.endTime
            this.formInline.couponRuleActivities = value.couponRuleActivities
            this.formInline.onePersonOneDayTimes = value.onePersonOneDayTimes
            this.formInline.onePersonTotalTimes = value.onePersonTotalTimes
            this.formInline.publishAmount = value.publishAmount
            this.formInline.status = value.status
            this.formInline.auditStatus = value.auditStatus === 0 ? '未审核' : '已审核'
            this.formInline.creatorName = value.creatorName
            this.formInline.createdTime = value.createdTime
            let receiveAmount = 0
            let usedAmount = 0
            value.couponRuleActivities.forEach(item => {
              receiveAmount += parseInt(item.receiveAmount)
              usedAmount += parseInt(item.usedAmount)
            })
            this.formInline.receiveAmount = receiveAmount
            this.formInline.usedAmount = usedAmount
            this.formInline.couponPushMode = value.couponPushMode || ''
            this.formInline.fireConditions = value.fireConditions || ''
          }
        }
        this.$request(opt)
      }
    },
    created() {
      this.handleGetDroplist()
    },
    mounted () {
      this.getDetailInfo()
    }
  }
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumb
    height: 35px;
  .content
    background: #FFFFFF;
    overflow: hidden;
</style>
<style>
.tip{
    position: absolute;
    left: 46px;
    top: 113px;
    color: gray;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: visible;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #20a0ff;
  }
  .avatar-uploader .no{
    position:absolute;
    left:100%;
    margin-left:10px;
    bottom:0;
    display: block;
    width:50px;
    color:gray;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .map {
    position: absolute;
    right:-100px;
    top:0;
  }
   .amap-demo {
      height: 500px;
      width: 100%;
    }

    .prompt {
      background: white;
      width: 100px;
      height: 30px;
      text-align: center;
    }
    .search {
      float: left;
    }
    .lnglat {
      float: left;
      margin-left:10px;
    }
    .map_bottom{
      padding-top:20px;
    }
    .map_button{
      float:right;
    }
</style>
